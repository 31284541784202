import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AccessRole, AdminUsersWithAccessRolesGetUserResponse } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { useEffect, useState } from "react";

export default function UsersWithAccessRolesTableComponent() {
	const [usersWithAccessRoles, setUsersWithAccessRoles] = useState<AdminUsersWithAccessRolesGetUserResponse[]>();
	const [validAccessRoles, setValidAccessRoles] = useState<AccessRole[]>([]);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [currentlySelectedUserWithAccessRole, setCurrentlySelectedUserWithAccessRole] = useState<AdminUsersWithAccessRolesGetUserResponse>();
	const [currentlySelectedAccessRole, setCurrentlySelectedAccessRole] = useState<AccessRole | undefined>();

	useEffect(() => {
		const fetchUsersWithAccessRoles = async () => {
			const response = await adminClient().adminUsersWithAccessRolesGet({});
			setUsersWithAccessRoles(response.users);
		}
		const fetchAllAccessRoles = async () => {
			const response = await adminClient().adminEnumValuesUserAccessRoleGet({});
			setValidAccessRoles(response.enumValues);
		}
		fetchUsersWithAccessRoles();
		fetchAllAccessRoles();
	}, []);

	const renderValidAccessRoles = (): JSX.Element[] => {
		if (!validAccessRoles) {
			return [<MenuItem key={""} value={""}>Loading</MenuItem>];
		}

		const accessRoles = validAccessRoles.map((accessRole) => (
			<MenuItem
				key={accessRole}
				value={accessRole}
				style={{
					textAlign: "right"
				}}>
				{accessRole}
			</MenuItem>));

		accessRoles.push(<MenuItem key="nullAccessRole" value={undefined}>-</MenuItem>);

		return accessRoles
	}

	const handleSelectChange = (userWithAccessRole: AdminUsersWithAccessRolesGetUserResponse, newAccessRole: AccessRole | undefined) => {
		setCurrentlySelectedUserWithAccessRole(userWithAccessRole);
		setCurrentlySelectedAccessRole(newAccessRole);
		setIsDialogOpen(true);
	}

	const handleAccessRoleChange = async () => {
		if (!currentlySelectedUserWithAccessRole) {
			return;
		}

		if (!currentlySelectedAccessRole) {
			await performAction(
				() => adminClient().adminUserAccessRoleDelete(currentlySelectedUserWithAccessRole.id),
				"Failed to delete user access role",
				true);
		} else {
			await performAction(
				() => adminClient().adminUserAccessRolePut(
					currentlySelectedUserWithAccessRole.id, {
					body: {
						userAccessRole: currentlySelectedAccessRole
					}
				}),
				"Failed to update user access role",
				true);
		}
	}

	if (!usersWithAccessRoles) {
		return <CircularProgress />
	}

	return <><TableContainer component={Paper}>
		<Table>
			<TableHead>
				<TableRow>
					<TableCell align="right">Access Role</TableCell>
					<TableCell align="right">Full Name</TableCell>
					<TableCell align="right">Employer Full Name</TableCell>
					<TableCell align="right">Id</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{usersWithAccessRoles.map((userWithAccessRole) => (
					<TableRow key={userWithAccessRole.id}>
						<TableCell
							align="right"
							style={{
								textAlign: "right"
							}}>
							<Select
								style={{ height: "100%" }}
								autoFocus={true}
								value={userWithAccessRole.accessRole}
								label={"Choose new state"}
								fullWidth
								onChange={(event) => handleSelectChange(userWithAccessRole, event.target.value as AccessRole | undefined)}>
								{renderValidAccessRoles()}
							</Select>
						</TableCell>
						<TableCell align="right">{userWithAccessRole.fullName}</TableCell>
						<TableCell align="right">{userWithAccessRole.employerFullName}</TableCell>
						<TableCell align="right">{userWithAccessRole.id}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
		<ConfirmationDialogComponent
			isDialogOpen={isDialogOpen}
			onClose={() => setIsDialogOpen(false)}
			onConfirm={async () => await handleAccessRoleChange()}
			title={
				currentlySelectedAccessRole ?
					`Update ${currentlySelectedUserWithAccessRole?.fullName}'s access role?` :
					`Delete ${currentlySelectedUserWithAccessRole?.fullName}'s access role?`
			}
			description={
				currentlySelectedAccessRole ?
					`Are you sure you want to change ${currentlySelectedUserWithAccessRole?.fullName}'s access role from "${currentlySelectedUserWithAccessRole?.accessRole}" → "${currentlySelectedAccessRole}"` :
					`Are you sure you want to delete ${currentlySelectedUserWithAccessRole?.fullName}'s access role? (They will be unable to log in to any administration)`
			}
		/>
	</>
}